import global from "global";
import { canUseDOM } from "exenv";
import React, { FC, ReactNode, useContext, useEffect, useState } from "react";
import { CurrentUserContextTypes, CurrentUserContext, initialCurrentUserContextValue, domId } from "~/contexts/currentUserContext";

export const CurrentUserContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [currentUserContextValue, setCurrentUserContextValue] = useState<CurrentUserContextTypes | null>(initialCurrentUserContextValue);

  useEffect(() => {
    const dataset = (canUseDOM && global.document.querySelector(domId) && global.document.querySelector(domId).dataset) || {
      ...initialCurrentUserContextValue,
    };

    const currentUser = {
      guest: dataset.guest,
      defaultLocale: dataset.defaultLocal,
      locale: dataset.locale,
      userId: dataset.userId,
      identifierId: dataset.identifierId,
      fullName: dataset.fullName,
      avatarUrl: dataset.avatarUrl,
      email: dataset.email,
      role: dataset.role,
      url: dataset.url,
      hasUnreadBadge: dataset.hasUnreadBadge === "true",
      organizationId: dataset.organizationId,
      organizationSubdomain: dataset.organizationSubdomain,
      organizationName: dataset.organizationName,
      organizationInhouse: dataset.organizationInhouse === "true",
      organizationFlyerOverviewActive: dataset.organizationFlyerOverviewActive === "true",
      organizationRecruiterSelfDepartmentOnlyView: dataset.organizationRecruiterSelfDepartmentOnlyView === "true",
      canCreateNote: dataset.canCreateNote === "true",
      canIndexDashboard: dataset.canIndexDashboard === "true",
      canIndexUsers: dataset.canIndexUsers === "true",
      canIndexFlyers: dataset.canIndexFlyers === "true",
      canIndexFlyerShares: dataset.canIndexFlyerShares === "true",
      canIndexFlyerApplications: dataset.canIndexFlyerApplications === "true",
      canIndexFlyerApplicationMails: dataset.canIndexFlyerApplicationMails === "true",
      canIndexProspects: dataset.canIndexProspects === "true",
      canIndexProspectProcessItems: dataset.canIndexProspectProcessItems === "true",
      canIndexTeamSetting: dataset.canIndexTeamSetting === "true",
      canShowProspectRegisterForm: dataset.canShowProspectRegisterForm === "true",
      canShowProspectMemo: dataset.canShowProspectMemo === "true",
      canIndexNotes: dataset.canIndexNotes === "true",
      canIndexDepartments: dataset.canIndexDepartments === "true",
      canIndexJobTitles: dataset.canIndexJobTitles === "true",
      canIndexOccupations: dataset.canIndexOccupations === "true",
      canIndexReadLogs: dataset.canIndexReadLogs === "true",
      canIndexMobilePushMessages: dataset.canIndexMobilePushMessages === "true",
      canIndexMailMessages: dataset.canIndexMailMessages === "true",
      canCreateProspectCustomProperty: dataset.canCreateProspectCustomProperty === "true",
      canIndexPageSettings: dataset.canIndexPageSettings === "true",
      canIndexLocations: dataset.canIndexLocations === "true",
    };

    (async () => {
      setCurrentUserContextValue(currentUser);
    })();
  }, []);

  if (!currentUserContextValue) {
    return null;
  }

  return <CurrentUserContext.Provider value={currentUserContextValue}>{children}</CurrentUserContext.Provider>;
};

export const useCurrentUser = () => {
  return useContext(CurrentUserContext);
};
